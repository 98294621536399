import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config.json';

export type FaqState = {
    isLoading: boolean;
    loaded: boolean;
    error: boolean;
    logs: any;
};

const initialState: FaqState = {
    isLoading: false,
    loaded: false,
    error: false,
    logs: [],
};

export const faqLogsSlice = createSlice({
    name: 'faqLogs',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
            state.loaded = false;
        },
        onLoadSuccess: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.loaded = true;
            state.logs = action.payload;
        },
        onLoadFail: (state) => {
            state.isLoading = false;
            state.error = true;
        },
    },
});

export const { startLoading, onLoadSuccess, onLoadFail } = faqLogsSlice.actions;

export const loadAll = createAsyncThunk('faq/loadAll', async (_, thunkAPI) => {
    thunkAPI.dispatch(startLoading());
    try {
        const response = await axios.get(`${config.api.url}/faq/logs`);

        if (response.status === 200) {
            thunkAPI.dispatch(onLoadSuccess(response.data));
        } else {
            throw new Error();
        }
    } catch (error) {
        thunkAPI.dispatch(onLoadFail());
    }
});

export default faqLogsSlice.reducer;
