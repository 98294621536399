import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { AuthState, refresh } from './auth.slice';

export default function PrivateZone(props: any) {
    const auth: AuthState = useAppSelector((state) => state.auth);
    const dispatch = useDispatch();

    const [goToLogin, setGoToLogin] = useState(false);

    useEffect(() => {
        if (!auth.isLoading && !auth.refreshFailed && !auth.logged) {
            dispatch(refresh());
        }

        if (!auth.logged && !auth.isLoading && auth.refreshFailed) {
            setGoToLogin(true);
        }
    });

    return (
        <React.Fragment>
            {!goToLogin ? (
                auth.user === null ? null : (
                    props.children
                )
            ) : (
                <Redirect to={{ pathname: '/login' }} />
            )}
        </React.Fragment>
    );
}
