import React from 'react';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import FaqListItem from './FaqListItem';
import { withTranslation } from 'react-i18next';
import './faq-list.css';
import { createFaq, loadAll, updateFaq } from './faq.slice';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from '../../app/store';

class FaqList extends React.Component<any, any> {
    timeout = null;

    constructor(props: any) {
        super(props);
        this.state = {
            newTitle: '',
            edit: {},
            newText: '',
            modalOpen: false,
        };
    }

    componentDidMount() {
        this.props.dispatch(loadAll());
    }

    handleCloseModal() {
        this.setState({
            edit: {},
            newTitle: '',
            newText: '',
            modalOpen: false,
        });
    }

    handleChanges(event: any) {
        this.setState({
            edit: {
                ...this.state.edit,
                [event.target.id]: event.target.value,
            },
        });

        const currentPath = this.getCurrentPath();

        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.props.dispatch(
                updateFaq({
                    id: currentPath[currentPath.length - 1],
                    title: this.state.edit.title,
                    text: this.state.edit.text,
                })
            );
        }, 700);
    }

    getCurrentPath() {
        return this.props.match.params['path'] === undefined
            ? []
            : this.props.match.params['path'].split('.');
    }

    render() {
        if (!this.props.faqState.loaded) {
            return null;
        }

        const currentPath = this.getCurrentPath();

        let currentFaqData = this.props.faqState.faq;
        currentPath.forEach((pathId) => {
            console.log(currentFaqData);
            currentFaqData = currentFaqData.children.find(
                (element) => element.id === parseInt(pathId, 10)
            );
        });

        return (
            <React.Fragment>
                {currentFaqData.title !== undefined ? (
                    <Form.Group className="with-padding" controlId="title">
                        <Form.Label>
                            {this.props.t('entities.title')}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            onChange={this.handleChanges.bind(this)}
                            value={
                                this.state.edit.title === undefined
                                    ? currentFaqData.title
                                    : this.state.edit.title
                            }
                        />
                    </Form.Group>
                ) : null}
                {currentFaqData.children.length > 0 ? (
                    <Table className="faq-list" bordered striped>
                        <thead>
                            <tr>
                                <th style={{ width: '1%' }}>
                                    {this.props.t('lists.id')}
                                </th>
                                <th>{this.props.t('entities.title')}</th>
                                <th
                                    style={{
                                        width: '1%',
                                    }}
                                >
                                    {this.props.t('lists.actions')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {[...currentFaqData.children]
                                .sort((a, b) => (a.id > b.id ? 1 : -1))
                                .map((faqData: any) => (
                                    <FaqListItem
                                        id={faqData.id}
                                        key={faqData.id}
                                        currentPath={currentPath}
                                        faqData={faqData}
                                    />
                                ))}
                        </tbody>
                    </Table>
                ) : null}
                <Button
                    className="new-button"
                    variant="success"
                    onClick={() => {
                        this.setState({
                            modalOpen: true,
                        });
                    }}
                >
                    {this.props.t('actions.new')}
                </Button>
                {currentFaqData.text !== undefined ? (
                    <Form.Group className="with-padding" controlId="text">
                        <Form.Label>{this.props.t('entities.text')}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={10}
                            onChange={this.handleChanges.bind(this)}
                            value={
                                this.state.edit.text === undefined
                                    ? currentFaqData.text
                                    : this.state.edit.text
                            }
                        />
                    </Form.Group>
                ) : null}
                <Modal
                    show={this.state.modalOpen}
                    onHide={() => {
                        this.handleCloseModal();
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.t('actions.new')}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form.Group controlId="title">
                            <Form.Label>
                                {this.props.t('entities.title')}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={this.state.newTitle}
                                onChange={(event: any) => {
                                    this.setState({
                                        newTitle: event.target.value,
                                    });
                                }}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.handleCloseModal.bind(this)}
                        >
                            {this.props.t('actions.close')}
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                if (this.state.newTitle.trim() !== '') {
                                    this.props.dispatch(
                                        createFaq({
                                            id:
                                                currentPath.length === 0
                                                    ? null
                                                    : currentPath[
                                                          currentPath.length - 1
                                                      ],
                                            title: this.state.newTitle,
                                        })
                                    );
                                }
                                this.handleCloseModal();
                            }}
                        >
                            {this.props.t('actions.add')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    faqState: state.faq,
});

export default withTranslation()(withRouter(connect(mapStateToProps)(FaqList)));
