import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from '../features/auth/auth.slice';
import managersReducer from '../features/managers/managers.slice';
import faqReducer from '../features/faq/faq.slice';
import faqLogsReducer from '../features/faqLogs/faqLogs.slice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        managers: managersReducer,
        faq: faqReducer,
        faqLogs: faqLogsReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
