import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import ManagerListItem from './ManagerListItem';
import { useTranslation } from 'react-i18next';
import './managers-list.css';
import { createManager, loadAll } from './managers.slice';

export default function ManagersList() {
    const { isLoading, loaded, error, list } = useAppSelector(
        (state) => state.managers
    );

    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => {
        setNewExternalId('');
        setNewPassword('');
        setShowModal(false);
    };
    const handleShowModal = () => setShowModal(true);

    const [newExternalId, setNewExternalId] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (!isLoading && !loaded && !error) {
            dispatch(loadAll());
        }
    });

    return (
        <React.Fragment>
            <Table className="managers-list" bordered striped>
                <thead>
                    <tr>
                        <th style={{ width: '100px' }}>{t('lists.id')}</th>
                        <th>{t('entities.externalId')}</th>
                        <th
                            style={{
                                width: '1%',
                            }}
                        >
                            {t('lists.actions')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {list.map((managerData: any) => (
                        <ManagerListItem
                            id={managerData.id}
                            key={managerData.id}
                            managerData={managerData}
                        />
                    ))}
                </tbody>
            </Table>
            <Button
                className="new-button"
                variant="success"
                onClick={handleShowModal}
            >
                {t('actions.new')}
            </Button>
            <Modal
                show={showModal}
                onHide={() => {
                    handleCloseModal();
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t('actions.new')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form.Group controlId="externalId">
                        <Form.Label>{t('entities.externalId')}</Form.Label>
                        <Form.Control
                            type="text"
                            value={newExternalId}
                            onChange={(event: any) => {
                                setNewExternalId(event.target.value);
                            }}
                        />
                    </Form.Group>
                    <Form.Group controlId="password">
                        <Form.Label>{t('entities.password')}</Form.Label>
                        <Form.Control
                            type="text"
                            value={newPassword}
                            onChange={(event: any) => {
                                setNewPassword(event.target.value);
                            }}
                        />
                    </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        {t('actions.close')}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            if (
                                newExternalId.trim() !== '' &&
                                newPassword.trim() !== ''
                            ) {
                                dispatch(
                                    createManager({
                                        externalId: newExternalId,
                                        password: newPassword,
                                    })
                                );
                            }
                            handleCloseModal();
                        }}
                    >
                        {t('actions.add')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}
