import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import ru from './locales/ru.json';

i18n.use(initReactI18next).init({
    debug: false,
    resources: {
        en: { translation: en },
        ru: { translation: ru },
    },
    fallbackLng: 'ru',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
