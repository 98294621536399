import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { removeFaq } from './faq.slice';

export default function FaqListItem(props: any) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const newPath = [...props.currentPath];
    newPath.push(props.id);

    return (
        <tr>
            <td>{props.id}</td>
            <td>{props.faqData.title}</td>
            <td
                style={{
                    whiteSpace: 'nowrap',
                }}
            >
                <Button
                    variant="info"
                    as={NavLink}
                    to={`/faq/${newPath.join('.')}`}
                >
                    {t('actions.edit')}
                </Button>{' '}
                <Button
                    variant="danger"
                    onClick={() => {
                        if (confirm(t('edit.confirmationQuestion'))) {
                            dispatch(removeFaq(props.id));
                        }
                    }}
                >
                    {t('actions.delete')}
                </Button>
            </td>
        </tr>
    );
}
