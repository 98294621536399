import React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RootState } from '../../app/store';
import { loadOne, saveManagerOptions } from './managers.slice';
import './manager-edit.css';
import { withTranslation } from 'react-i18next';

class ManagerEdit extends React.Component<any, any> {
    timeout: any = null;

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.dispatch(loadOne(this.props.match.params.id));
    }

    handleTextChange(event: any) {
        this.setState({
            ...this.state,
            [event.target.id]: event.target.value,
        });
        this.saveChanges();
    }

    handleCheckboxChange(event: any) {
        this.setState({
            ...this.state,
            [event.target.id]: event.target.checked,
        });
        this.saveChanges();
    }

    saveChanges() {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            const id: number = this.props.match.params.id;
            this.props.dispatch(
                saveManagerOptions({
                    id,
                    externalId: this.state.externalId,
                    password: this.state.password,
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    patronymName: this.state.patronymName,
                    canManageSurveys: this.state.canManageSurveys,
                    canModerateSurveys: this.state.canModerateSurveys,
                    canSeeAnswers: this.state.canSeeAnswers,
                    canManageManagers: this.state.canManageManagers,
                })
            );
        }, 700);
    }

    render() {
        const id = this.props.match.params.id;
        const manager: any = this.props.managers.managers[id];

        if (manager === undefined) {
            return null;
        }

        return (
            <div className="manager-edit">
                <Form.Group controlId="externalId">
                    <Form.Label>
                        {this.props.t('entities.externalId')}
                    </Form.Label>
                    <Form.Control
                        type="text"
                        value={this.state.externalId || manager.externalId}
                        onChange={this.handleTextChange.bind(this)}
                    />
                </Form.Group>
                <Form.Group controlId="password">
                    <Form.Label>{this.props.t('entities.password')}</Form.Label>
                    <Form.Control
                        type="text"
                        value={this.state.password || ''}
                        onChange={this.handleTextChange.bind(this)}
                    />
                </Form.Group>
                <Form.Group controlId="firstName">
                    <Form.Label>
                        {this.props.t('entities.firstName')}
                    </Form.Label>
                    <Form.Control
                        type="text"
                        value={this.state.firstName || manager.firstName}
                        onChange={this.handleTextChange.bind(this)}
                    />
                </Form.Group>
                <Form.Group controlId="lastName">
                    <Form.Label>{this.props.t('entities.lastName')}</Form.Label>
                    <Form.Control
                        type="text"
                        value={this.state.lastName || manager.lastName}
                        onChange={this.handleTextChange.bind(this)}
                    />
                </Form.Group>
                <Form.Group controlId="patronymName">
                    <Form.Label>
                        {this.props.t('entities.patronymName')}
                    </Form.Label>
                    <Form.Control
                        type="text"
                        value={this.state.patronymName || manager.patronymName}
                        onChange={this.handleTextChange.bind(this)}
                    />
                </Form.Group>
                <Form.Group controlId="canManageSurveys">
                    <Form.Check
                        type="checkbox"
                        label={this.props.t('entities.canManageSurveys')}
                        checked={
                            this.state.canManageSurveys !== undefined
                                ? this.state.canManageSurveys
                                : manager.canManageSurveys
                        }
                        onChange={this.handleCheckboxChange.bind(this)}
                    />
                </Form.Group>
                <Form.Group controlId="canModerateSurveys">
                    <Form.Check
                        type="checkbox"
                        label={this.props.t('entities.canModerateSurveys')}
                        checked={
                            this.state.canModerateSurveys !== undefined
                                ? this.state.canModerateSurveys
                                : manager.canModerateSurveys
                        }
                        onChange={this.handleCheckboxChange.bind(this)}
                    />
                </Form.Group>
                <Form.Group controlId="canSeeAnswers">
                    <Form.Check
                        type="checkbox"
                        label={this.props.t('entities.canSeeAnswers')}
                        checked={
                            this.state.canSeeAnswers !== undefined
                                ? this.state.canSeeAnswers
                                : manager.canSeeAnswers
                        }
                        onChange={this.handleCheckboxChange.bind(this)}
                    />
                </Form.Group>
                <Form.Group controlId="canManageManagers">
                    <Form.Check
                        type="checkbox"
                        label={this.props.t('entities.canManageManagers')}
                        checked={
                            this.state.canManageManagers !== undefined
                                ? this.state.canManageManagers
                                : manager.canManageManagers
                        }
                        onChange={this.handleCheckboxChange.bind(this)}
                    />
                </Form.Group>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    managers: state.managers,
});

export default withTranslation()(
    withRouter(connect(mapStateToProps)(ManagerEdit))
);
