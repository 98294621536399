import React from 'react';
import { Table } from 'react-bootstrap';
import FaqLogsListItem from './FaqLogsListItem';
import { withTranslation } from 'react-i18next';
import './faq-logs-list.css';
import { loadAll } from './faqLogs.slice';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from '../../app/store';

class FaqLogsList extends React.Component<any, any> {
    timeout = null;

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        this.props.dispatch(loadAll());
    }

    render() {
        const faqLogs = this.props.faqLogs;
        if (!this.props.faqLogs.loaded) {
            return null;
        }
        console.log(faqLogs);
        return (
            <React.Fragment>
                {faqLogs.logs.length > 0 ? (
                    <Table className="faq-list" bordered striped>
                        <thead>
                            <tr>
                                <th style={{ width: '1%' }}>
                                    {this.props.t('lists.id')}
                                </th>
                                <th style={{ width: '30%' }}>
                                    {this.props.t('entities.member')}
                                </th>
                                <th style={{ width: '50%' }}>
                                    {this.props.t('entities.title')}
                                </th>
                                <th style={{ width: '1%' }}>
                                    {this.props.t('entities.date')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {[...faqLogs.logs].map((faqLogData: any) => (
                                <FaqLogsListItem
                                    id={faqLogData.id}
                                    key={faqLogData.id}
                                    faqLogData={faqLogData}
                                />
                            ))}
                        </tbody>
                    </Table>
                ) : null}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    faqLogs: state.faqLogs,
});

export default withTranslation()(
    withRouter(connect(mapStateToProps)(FaqLogsList))
);
