import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config.json';

export type AuthState = {
    isLoading: boolean;
    error: boolean;
    refreshFailed: boolean;
    logged: boolean;
    user: any;
};

type AuthCredentials = {
    managerExternalId: string;
    password: string;
};

const initialState: AuthState = {
    isLoading: false,
    error: false,
    refreshFailed: false,
    logged: false,
    user: null,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
            state.error = false;
        },
        onAuthSuccess: (state, action: PayloadAction<any>) => {
            state.logged = true;
            state.isLoading = false;
            state.error = false;
            state.user = action.payload;
        },
        onAuthFail: (state) => {
            state.isLoading = false;
            state.error = true;
        },
        onRefreshSuccess: (state) => {
            state.logged = true;
            state.isLoading = false;
            state.error = false;
        },
        onRefreshFail: (state) => {
            state.isLoading = false;
            state.error = false;
            state.refreshFailed = true;
        },
        onLogout: (state) => {
            state.logged = false;
            state.isLoading = false;
            state.error = false;
        },
    },
});

export const {
    startLoading,
    onAuthSuccess,
    onAuthFail,
    onRefreshFail,
    onLogout,
} = authSlice.actions;

let timeout: any = null;
function onSuccessHandler(dispatch: any, seconds: number) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
        dispatch(refresh());
    }, seconds * 1000);
}

export const authLogin = createAsyncThunk(
    'auth/authLogin',
    async (credentials: AuthCredentials, thunkAPI) => {
        thunkAPI.dispatch(startLoading());
        try {
            const response = await axios.post(`${config.api.url}/auth/login`, {
                manager_external_id: credentials.managerExternalId,
                password: credentials.password,
            });

            if (response.status === 201) {
                if (response.data.success === true) {
                    onSuccessHandler(thunkAPI.dispatch, response.data.timeout);
                    thunkAPI.dispatch(onAuthSuccess(response.data.user));
                }
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onAuthFail());
        }
    }
);

export const refresh = createAsyncThunk('auth/refresh', async (_, thunkAPI) => {
    thunkAPI.dispatch(startLoading());
    try {
        const response = await axios.get(`${config.api.url}/auth/refresh`);

        if (response.status === 200) {
            if (response.data.success === true) {
                onSuccessHandler(thunkAPI.dispatch, response.data.timeout);
                thunkAPI.dispatch(onAuthSuccess(response.data.user));
            }
        } else {
            throw new Error();
        }
    } catch (error) {
        thunkAPI.dispatch(onRefreshFail());
    }
});

export const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
    thunkAPI.dispatch(onLogout());
    try {
        await axios.post(`${config.api.url}/auth/logout`);
    } catch (error) {
        thunkAPI.dispatch(onAuthFail());
    }
});

export default authSlice.reducer;
