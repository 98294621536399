import React from 'react';

export default function FaqLogsListItem(props: any) {
    const member = props.faqLogData.member;
    const faq = props.faqLogData.faq;
    const date = new Date(props.faqLogData.date);
    date.setHours(5);
    date.setMinutes(4);

    const getTime = () => {
        const h = date.getHours();
        let hStr = h.toString();
        if (h < 10) hStr = '0' + hStr;

        const m = date.getHours();
        let mStr = m.toString();
        if (m < 10) mStr = '0' + mStr;

        return hStr + ':' + mStr;
    };

    return (
        <tr>
            <td>{props.id}</td>
            <td>{`#${member.id} ${member.firstName} ${member.lastName}`}</td>
            <td>{faq.title}</td>
            <td>{`${date.toLocaleDateString('ru')} ${getTime()}`}</td>
        </tr>
    );
}
