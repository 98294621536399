import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config.json';

export type FaqState = {
    isLoading: boolean;
    loaded: boolean;
    error: boolean;
    currentPath: number[];
    faq: any;
};

const initialState: FaqState = {
    isLoading: false,
    loaded: false,
    error: false,
    currentPath: [],
    faq: {},
};

export const faqSlice = createSlice({
    name: 'faq',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
            state.loaded = false;
        },
        onLoadSuccess: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.loaded = true;
            state.faq = {
                children: action.payload,
            };
        },
        onLoadFail: (state) => {
            state.isLoading = false;
            state.error = true;
        },
    },
});

export const { startLoading, onLoadSuccess, onLoadFail } = faqSlice.actions;

export const loadAll = createAsyncThunk('faq/loadAll', async (_, thunkAPI) => {
    thunkAPI.dispatch(startLoading());
    try {
        const response = await axios.get(`${config.api.url}/faq`);

        if (response.status === 200) {
            thunkAPI.dispatch(onLoadSuccess(response.data));
        } else {
            throw new Error();
        }
    } catch (error) {
        thunkAPI.dispatch(onLoadFail());
    }
});

export const removeFaq = createAsyncThunk(
    'faq/remove',
    async (faqId: number, thunkAPI) => {
        try {
            const response = await axios.delete(
                `${config.api.url}/faq/${faqId}`
            );

            if (response.status === 200) {
                thunkAPI.dispatch(onLoadSuccess(response.data));
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export const createFaq = createAsyncThunk(
    'faq/create',
    async (data: any, thunkAPI) => {
        try {
            const response = await axios.post(
                `${config.api.url}/faq/${data.id === null ? 0 : data.id}`,
                {
                    title: data.title,
                }
            );

            if (response.status === 201) {
                thunkAPI.dispatch(onLoadSuccess(response.data));
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export type FaqOptions = {
    id?: number;
    title: string;
    text: string;
};

export const updateFaq = createAsyncThunk(
    'faq/update',
    async (options: FaqOptions, thunkAPI) => {
        try {
            const id = options.id;
            delete options.id;

            const response = await axios.put(
                `${config.api.url}/faq/${id}`,
                options
            );

            if (response.status === 200) {
                //thunkAPI.dispatch(onLoadOneSuccess(response.data));
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export default faqSlice.reducer;
