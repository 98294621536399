import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config.json';

export type ManagersState = {
    isLoading: boolean;
    loaded: boolean;
    error: boolean;
    list: any[];
    managers: Record<number, any>;
};

const initialState: ManagersState = {
    isLoading: false,
    loaded: false,
    error: false,
    list: [],
    managers: {},
};

export const managersSlice = createSlice({
    name: 'managers',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
            state.loaded = false;
        },
        onLoadSuccess: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.loaded = true;
            state.list = action.payload;
        },
        onLoadOneSuccess: (state, action: PayloadAction<any>) => {
            state.managers[action.payload.id] = action.payload;
        },
        onLoadFail: (state) => {
            state.isLoading = false;
            state.error = true;
        },
        onCreate: (state, action: PayloadAction<any>) => {
            state.list.push(action.payload);
        },
    },
});

export const {
    startLoading,
    onLoadSuccess,
    onLoadOneSuccess,
    onLoadFail,
    onCreate,
} = managersSlice.actions;

export const loadAll = createAsyncThunk(
    'managers/loadAll',
    async (_, thunkAPI) => {
        thunkAPI.dispatch(startLoading());
        try {
            const response = await axios.get(`${config.api.url}/managers`);

            if (response.status === 200) {
                thunkAPI.dispatch(onLoadSuccess(response.data));
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export const loadOne = createAsyncThunk(
    'managers/loadOne',
    async (managerId: number, thunkAPI) => {
        try {
            const response = await axios.get(
                `${config.api.url}/managers/${managerId}`
            );

            if (response.status === 200) {
                thunkAPI.dispatch(onLoadOneSuccess(response.data));
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export const removeManager = createAsyncThunk(
    'managers/remove',
    async (managerId: number, thunkAPI) => {
        thunkAPI.dispatch(startLoading());
        try {
            const response = await axios.delete(
                `${config.api.url}/managers/${managerId}`
            );

            if (response.status === 200) {
                thunkAPI.dispatch(onLoadSuccess(response.data));
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export const createManager = createAsyncThunk(
    'managers/create',
    async (data: any, thunkAPI) => {
        thunkAPI.dispatch(startLoading());
        try {
            const response = await axios.post(`${config.api.url}/managers`, {
                externalId: data.externalId,
                password: data.password,
            });

            if (response.status === 201) {
                thunkAPI.dispatch(onCreate(response.data));
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export type ManagerOptions = {
    id?: number;
    externalId: string;
    password: string;
    firstName: string;
    lastName: string;
    patronymName: string;
    canManageSurveys: boolean;
    canModerateSurveys: boolean;
    canSeeAnswers: boolean;
    canManageManagers: boolean;
};

export const saveManagerOptions = createAsyncThunk(
    'managers/saveOptions',
    async (options: ManagerOptions, thunkAPI) => {
        thunkAPI.dispatch(startLoading());
        try {
            const id = options.id;
            delete options.id;

            const response = await axios.put(
                `${config.api.url}/managers/${id}`,
                options
            );

            if (response.status === 200) {
                thunkAPI.dispatch(onLoadOneSuccess(response.data));
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export default managersSlice.reducer;
