import React, { useEffect, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { AuthState, refresh, authLogin } from './auth.slice';
import './login.css';

export default function Login() {
    const auth: AuthState = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const history = useHistory();

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        if (!auth.isLoading && !auth.refreshFailed && !auth.logged) {
            dispatch(refresh());
        }

        if (auth.logged) {
            history.push('/faq');
        }
    });

    const onLogin = (event: React.FormEvent) => {
        event.preventDefault();
        dispatch(
            authLogin({
                managerExternalId: login,
                password,
            })
        );
    };

    const errorDiv = auth.error ? <div>Error</div> : '';

    return (
        <Container className="text-center">
            <Form className="form-signin" onSubmit={onLogin}>
                <Form.Group controlId="formBasicEmail">
                    <Form.Control
                        onChange={(event) => {
                            setLogin(event.target.value);
                        }}
                        placeholder="Enter external ID"
                    />
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                    <Form.Control
                        onChange={(event) => {
                            setPassword(event.target.value);
                        }}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Group>
                <Button variant="primary" type="submit">
                    Login
                </Button>
                {errorDiv}
            </Form>
        </Container>
    );
}
