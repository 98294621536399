import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { AuthState, logout } from './auth.slice';

export default function Logout() {
    const auth: AuthState = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (auth.logged) {
            dispatch(logout());
        }
    });

    return <React.Fragment />;
}
